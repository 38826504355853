export default {
	validateEmail: function (email) {
		return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email));
	},
	capitalize: function (value) {
		if (!value)
			return "";
		value = value.toString();
		return value.charAt(0).toUpperCase() + value.slice(1);
	},
	showLoading() {
		const appLoading = document.getElementById('loading-request-bg');
		if (appLoading) {
			appLoading.style.display = "block";
		}
	},
	hideLoading() {
		const appLoading = document.getElementById('loading-request-bg');
		if (appLoading) {
			appLoading.style.display = "none";
		}
	},
	getCustomTimeZone() {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	},
	getCustomTimeZoneOffset() {
		var offset = new Date().getTimezoneOffset(),
			o = Math.abs(offset);
		return (
			(offset < 0 ? "+" : "-") +
			("00" + Math.floor(o / 60)).slice(-2) +
			":" +
			("00" + (o % 60)).slice(-2)
		);
	},
	getBaseUrl() {
		if (window.location.hostname === 'localhost') {
			return "https://dev.heatxtreme.com/api";
		} else {
			return "/api"
		}
	},
	checkUserPermission(action, slug, permission) {
		if (slug === "dashboard") {
			return true;
		}
		let slug_array = [
			action + "-all-" + slug,
			action + "-owner-" + slug,
			action + "-group-" + slug
		];
		for (let i = 0; i < slug_array.length; i++) {
			if (permission.indexOf(slug_array[i]) >= 0) {
				return true;
			}
		}
		return false;
	},
	showErrorMessage(obj, error) {
		let message =
			error.data && error.data.message ?
				error.data.message :
				error;
		obj.$vs.notify({
			title: "Error",
			text: message,
			color: "danger",
			autoHideDelay: 2000,
		});
	},
	showSuccessMessage(obj, message) {
		obj.$vs.notify({
			title: "Success",
			text: message,
			color: "success",
			autoHideDelay: 2000,
		});


	},
};