// axios
import axios from 'axios'
import router from './router';

var domain;
if (window.location.hostname === 'localhost') {
	 /*domain = "http://127.0.0.1:8000/api";*/
	domain = "https://www.santaletters2021.com/api/";
} else {
	domain = "/api"
}

const headers = {
	'X-Requested-With': 'XMLHttpRequest',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS, post, get',
	'Access-Control-Max-Age': '3600',
	'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
	'Access-Control-Allow-Credentials': 'true',
	'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
	'timezone_offset': getCustomTimeZoneOffset(),
};






function getCustomTimeZoneOffset() {
	var offset = new Date().getTimezoneOffset(),
		o = Math.abs(offset);
	return (
		(offset < 0 ? "+" : "-") +
		("00" + Math.floor(o / 60)).slice(-2) +
		":" +
		("00" + (o % 60)).slice(-2)
	);
}

var axiosObj = axios.create({
	baseURL: domain,
	timeout: 60000,
	headers: headers
});

axiosObj.interceptors.response.use((response) => {
	return response;
}, function (error) {
	if (error.response.status === 401) {
		router.replace('/login');
	}
	else if (error.response.status === 403) {
		router.replace('/403');
	}
	else if (error.response.status === 404) {
		router.replace('/404');
	}
	return Promise.reject(error.response);
});

export default axiosObj;
