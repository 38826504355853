var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('h4',{staticClass:"accordian_Title"},[_vm._v("Administration")])]),_c('sidebar-item',{attrs:{"link":{
					name: 'Dashboard',
					path: '/account/dashboard',
					icon: 'fa fa-home text-primary',
				}}}),(
					this.checkUserPermission('users') ||
					this.checkUserPermission('actions') ||
					this.checkUserPermission('modules') ||
					this.checkUserPermission('roles') ||
					this.checkUserPermission('permissions') ||
					this.checkUserPermission('emailtemplates') ||
					this.checkUserPermission('address') ||
					this.checkUserPermission('batch')
				)?_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[(_vm.checkUserPermission('users'))?_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('h4',{staticClass:"accordian_Title"},[_vm._v("Authentication")])]):_vm._e(),_c('b-collapse',{attrs:{"visible":"","accordion":"my-accordion","role":"tabpanel"}},[(_vm.checkUserPermission('users'))?_c('sidebar-item',{attrs:{"link":{
								name: 'Users',
								path: '/account/users',
								icon: 'fa fa-user text-primary',
							}}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
								name: 'Profile',
								path: '/account/profile',
								icon: 'fa fa-user-circle-o text-primary',
							}}}),(_vm.checkUserPermission('actions'))?_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('h4',{staticClass:"accordian_Title"},[_vm._v("Authorization")])]):_vm._e(),(_vm.checkUserPermission('actions'))?_c('sidebar-item',{attrs:{"link":{
								name: 'Actions',
								path: '/account/actions',
								icon: 'fa fa-cog text-primary',
							}}}):_vm._e(),(_vm.checkUserPermission('modules'))?_c('sidebar-item',{attrs:{"link":{
								name: 'Modules',
								path: '/account/modules',
								icon: 'fa fa-flask text-primary',
							}}}):_vm._e(),(_vm.checkUserPermission('roles'))?_c('sidebar-item',{attrs:{"link":{
								name: 'Roles',
								path: '/account/roles',
								icon: 'fa fa-id-badge text-primary',
							}}}):_vm._e(),(_vm.checkUserPermission('permissions'))?_c('sidebar-item',{attrs:{"link":{
								name: 'Permissions',
								path: '/account/permissions',
								icon: 'fa fa-lock text-primary',
							}}}):_vm._e(),(_vm.checkUserPermission('emailtemplates'))?_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('h4',{staticClass:"accordian_Title"},[_vm._v("Email Settings")])]):_vm._e(),(_vm.checkUserPermission('emailtemplates'))?_c('sidebar-item',{attrs:{"link":{
								name: 'Email Templates',
								path: '/account/emailtemplates',
								icon: 'fa fa-envelope-o text-primary',
							}}}):_vm._e(),_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('h4',{staticClass:"accordian_Title"},[_vm._v("Addresses List")])]),(_vm.checkUserPermission('address'))?_c('sidebar-item',{attrs:{"link":{
								name: 'Address',
								path: '/account/address',
								icon: 'fa fa-map-marker text-primary',
							}}}):_vm._e(),(_vm.checkUserPermission('batch'))?_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('h4',{staticClass:"accordian_Title"},[_vm._v(" Batches & Export ")])]):_vm._e(),(_vm.checkUserPermission('batch'))?_c('sidebar-item',{attrs:{"link":{
								name: 'Batch Process',
								path: '/account/batch',
								icon: 'fa fa-tasks text-primary',
							}}}):_vm._e()],1)],1)],1):_vm._e()],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }