import axios from "../../axios";
import router from "../../router";
const state = {
	token: null,
	user: null,
};

const getters = {
	isAuthenticated(state) {
		return (state.token && state.user)
	},
	getAuthenticatedUser(state) {
		return state.user
	},
	getAuthenticatedToken(state) {
		return state.token
	}
};

const actions = {
	// async register({ dispatch }, form) {
	// 	await axios.post('auth/register', form)
	// 	let UserForm = new FormData()
	// 	UserForm.append('email', form.email)
	// 	UserForm.append('password', form.password)
	// 	await dispatch('logIn', UserForm)
	// },

	async logIn({ dispatch }, user) {
		let response = await axios.post("auth/login", user);
		if (response.data.status == false) { // on error send response to frontend
			return response.data;
		} else {
			await dispatch("setUser", response.data.accessToken);
			return response.data;
		}
	},

	async setUser({ commit, state }, token) {
		if (token) {
			await commit('setToken', token)
		}
		if (!state.token) {
			return;
		}
		try {
			var response = await axios.get('auth/profile').catch(() => {
				token.notify({
					title: 'Login Attempt',
					text: 'Invalid Credentials',
					iconPack: 'feather',
					icon: 'icon-alert-circle',
					color: 'warning'
				})
			});
			commit('setUser', response.data)
		} catch (e) {
			commit('updateUser', null)
			commit('setToken', null)
		}
	},

	async attempt({ commit, state }, token) {
		if (token) {
			commit('setToken', token)
		}
		if (!state.token) {
			return;
		}
		try {
			var response = await axios.get('auth/profile').catch(() => {
				token.notify({
					title: 'Login Attempt',
					text: 'Invalid Credentials',
					iconPack: 'feather',
					icon: 'icon-alert-circle',
					color: 'warning'
				})
			});
			commit('updateUser', response.data)
		} catch (e) {
			commit('updateUser', null)
			commit('setToken', null)
		}
	},

	async logOut({ commit }) {
		return axios.get('auth/logout').then(() => {
			commit('setToken', null)
			commit('updateUser', null)
			axios.defaults.headers.common['Authorization'] = null;
			localStorage.removeItem('token');
		});
	},
};

const mutations = {
	setUser(state, data) {
		if (data !== null) {
			state.user = data
			state.user.photoURL = data.avatar
			state.user.status = "online"
			state.user.userRole = "admin"
			let url = '/account/dashboard';
			router.push(router.currentRoute.query.to || url);
		}
	},
	updateUser(state, data) {
		if (data !== null) {
			state.user = data
			state.user.photoURL = data.avatar
			state.user.status = "online"
			state.user.userRole = "admin"
		}
	},
	setToken(state, token) {
		if (token || token === null) {
			state.token = token
		}
		if (token === null) {
			state.user = null;
		}
	},
	logOut(state, user) {
		state.user = user;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
