<template>
	<div class="wrapper">
		<notifications></notifications>

		<side-bar>
			<template slot="links">
				<b-card-header header-tag="header" class="p-1" role="tab">
					<h4 class="accordian_Title">Administration</h4>
				</b-card-header>
				<sidebar-item
					:link="{
						name: 'Dashboard',
						path: '/account/dashboard',
						icon: 'fa fa-home text-primary',
					}"
				>
				</sidebar-item>

				<div
					class="accordion"
					role="tablist"
					v-if="
						this.checkUserPermission('users') ||
						this.checkUserPermission('actions') ||
						this.checkUserPermission('modules') ||
						this.checkUserPermission('roles') ||
						this.checkUserPermission('permissions') ||
						this.checkUserPermission('emailtemplates') ||
						this.checkUserPermission('address') ||
						this.checkUserPermission('batch')
					"
				>
					<b-card no-body class="mb-1">
						<b-card-header
							header-tag="header"
							class="p-1"
							role="tab"
							v-if="checkUserPermission('users')"
						>
							<h4 class="accordian_Title">Authentication</h4>
						</b-card-header>
						<b-collapse
							visible
							accordion="my-accordion"
							role="tabpanel"
						>
							<sidebar-item
								:link="{
									name: 'Users',
									path: '/account/users',
									icon: 'fa fa-user text-primary',
								}"
								v-if="checkUserPermission('users')"
							>
							</sidebar-item>
							<sidebar-item
								:link="{
									name: 'Profile',
									path: '/account/profile',
									icon: 'fa fa-user-circle-o text-primary',
								}"
							>
							</sidebar-item>

							<b-card-header
								header-tag="header"
								class="p-1"
								role="tab"
								v-if="checkUserPermission('actions')"
							>
								<h4 class="accordian_Title">Authorization</h4>
							</b-card-header>
							<sidebar-item
								:link="{
									name: 'Actions',
									path: '/account/actions',
									icon: 'fa fa-cog text-primary',
								}"
								v-if="checkUserPermission('actions')"
							>
							</sidebar-item>
							<sidebar-item
								:link="{
									name: 'Modules',
									path: '/account/modules',
									icon: 'fa fa-flask text-primary',
								}"
								v-if="checkUserPermission('modules')"
							>
							</sidebar-item>
							<sidebar-item
								:link="{
									name: 'Roles',
									path: '/account/roles',
									icon: 'fa fa-id-badge text-primary',
								}"
								v-if="checkUserPermission('roles')"
							>
							</sidebar-item>
							<sidebar-item
								:link="{
									name: 'Permissions',
									path: '/account/permissions',
									icon: 'fa fa-lock text-primary',
								}"
								v-if="checkUserPermission('permissions')"
							>
							</sidebar-item>

							<b-card-header
								header-tag="header"
								class="p-1"
								role="tab"
								v-if="checkUserPermission('emailtemplates')"
							>
								<h4 class="accordian_Title">Email Settings</h4>
							</b-card-header>

							<sidebar-item
								:link="{
									name: 'Email Templates',
									path: '/account/emailtemplates',
									icon: 'fa fa-envelope-o text-primary',
								}"
								v-if="checkUserPermission('emailtemplates')"
							>
							</sidebar-item>
							<b-card-header
								header-tag="header"
								class="p-1"
								role="tab"
							>
								<h4 class="accordian_Title">Addresses List</h4>
							</b-card-header>

							<sidebar-item
								:link="{
									name: 'Address',
									path: '/account/address',
									icon: 'fa fa-map-marker text-primary',
								}"
								v-if="checkUserPermission('address')"
							>
							</sidebar-item>
							<b-card-header
								header-tag="header"
								class="p-1"
								role="tab"
								v-if="checkUserPermission('batch')"
							>
								<h4 class="accordian_Title">
									Batches & Export
								</h4>
							</b-card-header>
							<sidebar-item
								:link="{
									name: 'Batch Process',
									path: '/account/batch',
									icon: 'fa fa-tasks text-primary',
								}"
								v-if="checkUserPermission('batch')"
							>
							</sidebar-item>
						</b-collapse>
					</b-card>
				</div>
			</template>
		</side-bar>
		<div class="main-content">
			<dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

			<div @click="$sidebar.displaySidebar(false)">
				<fade-transition
					:duration="200"
					origin="center top"
					mode="out-in"
				>
					<!-- your content here -->
					<router-view :key="$route.fullPath"></router-view>
				</fade-transition>
			</div>
			<content-footer v-if="!$route.meta.hideFooter"></content-footer>
		</div>
	</div>
</template>
<script>
	// @/views/Layout/DashboardLayout.vue
	/* eslint-disable no-new */
	import PerfectScrollbar from "perfect-scrollbar";
	import "perfect-scrollbar/css/perfect-scrollbar.css";

	function hasElement(className) {
		return document.getElementsByClassName(className).length > 0;
	}

	function initScrollbar(className) {
		if (hasElement(className)) {
			new PerfectScrollbar(`.${className}`);
		} else {
			// try to init it later in case this component is loaded async
			setTimeout(() => {
				initScrollbar(className);
			}, 100);
		}
	}

	import DashboardNavbar from "./pageSections/dashboardNavBar.vue";
	import ContentFooter from "./pageSections/dashboardFooter.vue";
	// import DashboardContent from "./pageSections/Content.vue";
	import { FadeTransition } from "vue2-transitions";

	export default {
		components: {
			DashboardNavbar,
			ContentFooter,
			// DashboardContent,
			FadeTransition,
		},
		methods: {
			initScrollbar() {
				let isWindows = navigator.platform.startsWith("Win");
				if (isWindows) {
					initScrollbar("sidenav");
				}
			},
			checkUserPermission(slug) {
				if (this.activeUserInfo) {
					return this.$helper.checkUserPermission(
						"navigate",
						slug,
						this.activeUserInfo.allPermissions
					);
				}
			},
		},
		mounted() {
			this.initScrollbar();
		},
		computed: {
			activeUserInfo() {
				return this.$store.state.authentication.user;
			},
		},
	};
</script>
<style lang="scss">
</style>
