<template>
	<base-nav
		container-classes="container-fluid"
		class="navbar-top navbar-expand"
		:class="{ 'navbar-dark': type === 'default' }"
	>
		<!-- <a
			href="#"
			aria-current="page"
			class="
				h4
				mb-0
				text-white text-uppercase
				d-none d-lg-inline-block
				active
				router-link-active
			"
		> -->
		<!-- {{ moduleName }} -->
		<!-- <span v-html="HTMLcontent"></span> -->
		<!-- </a> -->

		<b-navbar-nav class="align-items-center ml-md-auto"> </b-navbar-nav>
		<b-navbar-nav class="align-items-center ml-auto ml-md-0">
			<base-dropdown
				menu-on-right
				class="nav-item"
				tag="li"
				title-tag="a"
				title-classes="nav-link pr-0"
				v-if="activeUserInfo"
			>
				<a
					href="#"
					class="nav-link pr-0"
					@click.prevent
					slot="title-container"
				>
					<b-media no-body class="align-items-center">
						<span class="avatar avatar-sm rounded-circle">
							<img
								alt="Image placeholder"
								:src="activeUserInfo.profile_image"
							/>
						</span>
						<b-media-body class="ml-2 d-none d-lg-block">
							<span class="mb-0 text-sm font-weight-bold">{{
								activeUserInfo.name
							}}</span>
						</b-media-body>
					</b-media>
				</a>

				<template>
					<b-dropdown-header class="noti-title">
						<h6 class="text-overflow m-0">Welcome !</h6>
					</b-dropdown-header>
					<b-dropdown-item @click="goToProfile">
						<i class="ni ni-single-02"></i>
						<span>My profile</span>
					</b-dropdown-item>
					<div class="dropdown-divider"></div>
					<b-dropdown-item @click="logOutUser">
						<i class="ni ni-user-run"></i>
						<span>Logout</span>
					</b-dropdown-item>
				</template>
			</base-dropdown>
		</b-navbar-nav>
	</base-nav>
</template>
<script>
	export default {
		props: {
			type: {
				type: String,
				default: "default", // default|light
				description:
					"Look of the dashboard navbar. Default (Green) or light (gray)",
			},
		},
		computed: {
			activeUserInfo() {
				return this.$store.state.authentication.user;
			},
		},
		data() {
			return {
				moduleName: "",
			};
		},
		created() {
			this.moduleName = this.$route.params.module;
		},
		methods: {
			logOutUser() {
				this.$helper.showLoading();
				this.$store.dispatch("logOut").then(() => {
					this.$helper.hideLoading();
					this.$helper.showSuccessMessage(
						this,
						"You are successfully logged out!"
					);
					this.$router.replace("/login").catch((error) => {
						this.$helper.hideLoading();
						this.$helper.showErrorMessage(this, error);
					});
				});
			},
			goToProfile() {
				this.$router.replace("/account/profile");
			},
		},
	};
</script>
