import DashboardLayout from '../layouts/dashboardLayout';
import fullWidth from '../layouts/fullWidth';

import NotFound from '@/views/NotFoundPage.vue';

const routes = [
	{
		path: '/',
		redirect: '/account/dashboard',
		component: DashboardLayout,
		meta: { requireAuthentication: true },
		children: [
			{
				path: '/account/dashboard',
				name: 'dashboard',

				component: () => import('../views/Dashboard.vue')
			},
			{
				path: '/account/profile',
				name: 'profile',
				component: () => import('../views/Pages/UserProfile.vue')
			},
			{
				path: 'account/:module',
				name: ':module',
				component: () => import('../views/modules/list'),
			},
			{
				path: 'account/:module/create',
				name: ':module',
				component: () => import('../views/modules/createOrUpdate'),
			},
			{
				path: 'account/:module/view/:id',
				name: ':module',
				component: () => import('../views/modules/createOrUpdate'),
			},
			{
				path: 'account/:module/list',
				name: ':module',
				component: () => import('../views/modules/batch-address-list'), // newly added only for batch section
			}
		]
	},
	{
		path: '/',
		redirect: 'login',
		component: fullWidth,
		children: [
			{
				path: '/login',
				name: 'login',
				component: () => import('../views/users/login'),
				meta: { isGuest: true },
			},
			{
				path: '/register',
				name: 'register',
				component: () => import('../views/users/register'),
				meta: { isGuest: true },
			},
			{
				path: '/forgot-password',
				name: 'forgot-password',
				component: () => import('../views/users/forgotPassword'),
				meta: { isGuest: true },
			},
			{
				path: '/set-password',
				name: 'set-password',
				component: () => import('../views/users/setPassword'),
				meta: { isGuest: true },
			},
			{ path: '*', component: NotFound }
		]
	}
];

export default routes;
