<template>
	<div class="main-content bg-white">
		<full-width-header></full-width-header>
			<router-view :key="$route.fullPath"></router-view>
		<full-width-footer></full-width-footer>
	</div>
</template>
<script>
	//  refer to the layout in pages named AuthLayout for any problem.
	import fullWidthHeader from "./pageSections/header.vue";
	import fullWidthFooter from "./pageSections/footer.vue";

	export default {
		components: {
			fullWidthHeader,
			fullWidthFooter,
		},
	};
</script>
