import axios from "@/axios.js";
import store from "@/store/index.js";

store.subscribe((mutation) => {
	switch (mutation.type) {
		case 'setToken':
			if (mutation.payload !== null) {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + mutation.payload;
				localStorage.setItem('token', mutation.payload);
			} else {
				axios.defaults.headers.common['Authorization'] = null;
				localStorage.removeItem('token');
			}
			break;
	}
})