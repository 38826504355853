import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';
import store from "../store"

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		// { path: '/', redirect: { name: 'some-other-link' } },
		...routes,
		{
			path: '*',
			redirect: 'error-404',
		},
	],
})

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requireAuthentication)) {
		if (store.getters.isAuthenticated) {
			next();
			return;
		}
		next("/login");
	} else {
		next();
	}
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.isGuest)) {
		if (store.getters.isAuthenticated) {
			next("/account/dashboard");
			return;
		}
		next();
	} else {
		next();
	}
});


router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		// appLoading.style.display = 'none'
	}
})

export default router
