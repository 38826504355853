<template>
	<base-nav
		v-model="showMenu"
		:transparent="true"
		menu-classes="justify-content-end"
		class="navbar-horizontal navbar-main navbar-top navbar-dark"
		expand="lg"
	>
		<!-- <div slot="brand" class="navbar-wrapper">
			<b-navbar-brand to="/">
				<img src="@/assets/images/logo.png" />
			</b-navbar-brand>
		</div> -->

		<template>
			<div class="navbar-collapse-header">
				<b-row>
					<b-col cols="6" class="collapse-brand">
						<router-link to="/">
							<img src="@/assets/images/logo.png" />
						</router-link>
					</b-col>
					<b-col cols="6" class="collapse-close">
						<button
							type="button"
							class="navbar-toggler"
							@click="showMenu = false"
						>
							<span></span>
							<span></span>
						</button>
					</b-col>
				</b-row>
			</div>
			<!-- <b-navbar-nav class="align-items-lg-center ml-lg-auto">
				<b-nav-item @click="goToPage('/register')">
					<i class="ni ni-circle-08"></i>
					<span class="nav-link-inner--text">Register</span>
				</b-nav-item>
				<b-nav-item @click="goToPage('/login')">
					<i class="ni ni-key-25"></i>
					<span class="nav-link-inner--text">Login</span>
				</b-nav-item>
			</b-navbar-nav> -->
		</template>
	</base-nav>
</template>
<script>
	import { BaseNav } from "@/components";
	export default {
		components: {
			BaseNav,
		},
		data() {
			return {
				showMenu: false,
			};
		},
		methods: {
			goToPage(slugUrl) {
				this.$router.replace(slugUrl);
			},
		},
	};
</script>