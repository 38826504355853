
<template>
	<div>
		<div class="justify-content-center" id="loading-request-bg">
			<div class="loading-request-container">
				<div class="loading-request-logo">
					<img :src="sitelogo" alt="Logo" />
				</div>
				<div class="loading-request-loading">
					<div class="effect-1 effects"></div>
					<div class="effect-2 effects"></div>
					<div class="effect-3 effects"></div>
				</div>
			</div>
		</div>
		<router-view />
	</div>
</template>
<script>
	import "./assets/css/custom_theme.css";
	import sitelogo from "@/assets/loader.png";
	export default {
		name: "App",
		data() {
			return {
				sitelogo,
			};
		},
		beforeCreate() {
			this.$helper.showLoading();
		},
		mounted() {
			this.$helper.hideLoading();
		},
	};
</script>
