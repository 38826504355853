<template>
	<footer class="footer px-4">
		<b-row align-v="center" class="justify-content-lg-between">
			<b-col lg="6">
				<b-nav align="center" class="nav-footer justify-content-lg-end">
					<!-- <b-nav-item
						href="https://www.creative-tim.com/presentation"
						target="_blank"
					>
						
					</b-nav-item> -->
				</b-nav>
			</b-col>
		</b-row>
	</footer>
</template>
<script>
	export default {};
</script>
<style></style>
