import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import authentication from "./modules/authentication";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		authentication,
	},
	plugins: [createPersistedState()],
});